import React from "react";
import dynamic from "next/dynamic";
import Head from "next/head";
import { readDirectory } from "lib/fs/fs";
import { GetStaticProps } from "next";
import { FileRef } from "lib/fs/fs";
import { useSession } from "next-auth/react";

import BeaconThemeProvider from "@braze/beacon-styling";

import { Prompt } from "scenes/SignIn/SignIn.style";

const SignIn = dynamic(() => import("scenes/SignIn/SignIn"), {
  ssr: false,
  loading: () => <p>...</p>,
});
const Webinole = dynamic(() => import("scenes/Webinole/Webinole"), {
  ssr: false,
  loading: () => <Prompt>...</Prompt>,
});

export interface HomePageProps {
  presetHtmlIams: FileRef[];
}

const HomePage = ({ presetHtmlIams }: HomePageProps): React.ReactElement => {
  const { data: session } = useSession();

  return (
    <>
      <Head>
        <title>Webinole</title>
        <link
          type="text/css"
          rel="stylesheet"
          href="https://fast.fonts.net/cssapi/34a7ffbc-6962-4ab8-b4f5-9aa2b4258efc.css"
        />
        <link type="text/css" rel="stylesheet" href="/index.css" />
      </Head>
      <BeaconThemeProvider>
        {session && (
          <Webinole presetHtmlIams={presetHtmlIams} signedInUser={session.user?.name || session.user?.email} />
        )}
        {(!session || !session.user) && <SignIn />}
      </BeaconThemeProvider>
    </>
  );
};

export const getStaticProps: GetStaticProps = async () => {
  return {
    props: {
      presetHtmlIams: readDirectory("html-presets"),
    },
  };
};

export default HomePage;
